import React, { useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useFormik } from "formik";
import * as Yup from "yup";
import { login } from '../../../api/auth';
import PNotify from '../../features/elements/p-notify';

function SignIn ( props ) {

    useEffect( () => {
        document.querySelector( 'body' ).classList.add( 'loaded' );
    }, [] )

      const formik = useFormik({
    initialValues: {
      email: "",
              password: "",
              role:"Administrator"
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(3, "Must be 3 characters or more")
        .required("Required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Required"),
    }),
    onSubmit: (values) => {
        console.log(values)
        login(values).then(res => {
          console.log(res)
            if (res.status) {
                   localStorage.setItem("token", res.data.result.token);
        localStorage.setItem("user", JSON.stringify(res.data.result));
                 props.history.push( `${ process.env.PUBLIC_URL }/dashboard` );
            }
        }).catch((error) => {
            console.log(error)
    //            toast(
    //     <PNotify title="Error" icon="fas fa-times" text={error} />,
    //     {
    //       containerId: "default",
    //       className: "notification-danger",
    //     }
    //   );
         })
             
          
    },
  });

    
    function signIn ( e ) {
        e.preventDefault();
        props.history.push( `${ process.env.PUBLIC_URL }/dashboard` );
    }

    return (
        <section className="body-sign">
            <div className="center-sign">
                <Card className="card-sign">
                    <Card.Body>
                        <h2 className="sign-title">Sign In</h2>
                        <Form onSubmit={formik.handleSubmit}>
                            <Form.Group className="form-custom-group">
                                <Form.Label>Email Address <span className="required">*</span></Form.Label>
                                <Form.Control
                                    type="email"
                                    autoFocus 
                                 name="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                       />
                    {formik.touched.email && formik.errors.email ? (
                      <div className='text-danger'>{formik.errors.email}</div>
                    ) : null}
                      
                            </Form.Group>

                            <Form.Group className="form-custom-group">
                                <Form.Label className="float-left">Password <span className="required">*</span></Form.Label>
                                <Form.Control type="password"    name="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                                    value={formik.values.password} />
                                 {formik.touched.password && formik.errors.password ? (
                      <div className='text-danger'>{formik.errors.password}</div>
                    ) : null}
                            </Form.Group>

                            <Row className="rem-password">
                                <Col sm={ 8 }>
                                    {/* <Form.Check
                                        custom
                                        id="remember"
                                        className="checkbox-default"
                                        label="Remember me"
                                    /> */}
                                </Col>

                                <Col sm={ 4 }>
                                    <Link to={ `${ process.env.PUBLIC_URL }/forgot-password` }>Forgot Password?</Link>
                                </Col>
                            </Row>

                            <Button
                                type="submit"
                                className="btn-login"
                                variant=""
                                block
                            >Sign In</Button>

                            {/* <span className="my-3 line-thru text-center text-uppercase">
                                <span>or</span>
                            </span>

                            <div className="mb-1 text-center">
                                <Button
                                    href="#"
                                    className="mb-3 mx-1"
                                    variant="facebook">
                                    Connect with <i className="fab fa-facebook-f"></i>
                                </Button>
                                <Button
                                    href="#"
                                    className="mb-3 mx-1"
                                    variant="twitter">
                                    Connect with <i className="fab fa-twitter"></i>
                                </Button>
                            </div>

                            <p className="text-center">
                                Don't have an account yet? <Link to={ `${ process.env.PUBLIC_URL }/pages/sign-up` }>Sign Up!</Link>
                            </p> */}
                        </Form>
                    </Card.Body>
                </Card>

                <p className="text-center text-muted my-3">&copy; Copyright 2024. All Rights Reserved.</p>
            </div>
        </section>
    )
}

export default React.memo( withRouter( SignIn ) );