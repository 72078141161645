import React, { useState, useEffect, useMemo } from 'react';

import axios from "axios";
import { toast } from 'react-toastify';
import PNotify from '../components/features/elements/p-notify';

const API_URL = process.env.REACT_APP_BACKEND_URL;
// ./../features/elements/p-notify
export const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.token = `${token}`;
    }
    return config;
  },
  (error) => {
    // alert(JSON.stringify(error))
    console.log(error)
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // alert(JSON.stringify(error))
    console.log(error)   
    if (error.response) {
      if(error.response.status==500){
        toast(
          <PNotify title="Error" icon="fas fa-times" text={error.response.data.error} />,
          {
              containerId: "default",
              className: "notification-danger"
          })
        console.log(error.response.data); 
        localStorage.clear(); 

        window.location.href = "/";
        return;
      }
      console.log(error.response.data); 
      toast(
        <PNotify title="Error" icon="fas fa-times" text={error.response.data.message} />,
        {
            containerId: "default",
            className: "notification-danger"
        })
      // Response headers
    } else if (error.request) {
      // The request was made but no response was received
      console.log(error.request);
    } else {
      // Something else happened while setting up the request
      console.log('Error', error.message);
    }
    return Promise.reject(error);
  }
);

export default api;