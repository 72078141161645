// ** Axios Imports

import { throws } from "assert";
import axios from "axios";
import api from "../library/library";

const API_URL = process.env.REACT_APP_BACKEND_URL;

export const login = (payload)=> {
  return api.post(`${API_URL}/auth/login`, payload)
}

// export const login = function(payload) {
//   return axios
//     .post(`${API_URL}/auth/login`, payload)
//     .then((response) => {
//       console.log(response.data);
//       return response.data;
//     })
//     .catch((error) => {
//        throw error.response.data.error;
//     });
// };

export const forgetPassword = (payload) => {
  return api.post(`${API_URL}/auth/forgetpassword`, payload)
}

// export const forgetPassword = (payload) => {
//   return axios
//     .post(`${API_URL}/auth/forgetpassword`, payload)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       console.error(error);
//     });
// };

export const resetPassword = (payload) => {
  return api.post(`${API_URL}/auth/resetpassword`, payload)
}

// export const resetPassword = (payload) => {
//   return axios
//     .post(`${API_URL}/auth/resetpassword`, payload)
//     .then((response) => {
//       return response.data;
//     })
//     .catch((error) => {
//       console.error(error);
//     });
// };
